<template>
  <div class="container headerContainer" v-if="!isLoading && item">
    <div class="row g-2 g-lg-3 align-items-center">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-6 col-12 photoSide"
        :style="{
          backgroundImage: 'url(' + server_url + '/' + item.image + ')',
        }"
      ></div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-12 pl-0 pr-0">
        <div class="titleSide mt-3">
          <h4 class="mtavruli purple">
            <span v-if="lang == 'ka'" v-html="item.titleKA">
              {{ item.titleKA }}</span
            >
            <span v-else v-html="item.titleEN"> {{ item.titleEN }}</span>
          </h4>
          <div class="textScroll">
            <p
              v-if="lang == 'ka'"
              v-html="item.descriptionKA"
              class="card-text blackColor"
            >
              {{ item.descriptionKA }}
            </p>
            <p v-else v-html="item.descriptionEN" class="card-text blackColor">
              {{ item.descriptionEN }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import env from "../../../../env.json";
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      item: null,
      lang: "ka",
      server_url: env.server_url,
    };
  },
  mounted() {
    this.isLoading = true;
    axios.get(`${env.host}/get/informations/blog`).then((result) => {
      this.item = result.data.item;
      this.isLoading = false;
    });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.row {
  padding: 0px;
  padding-right: 25px;
  margin: 0px;
}
.headerContainer {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-width: 1400px;
  margin: auto;
}
.photoSide {
  height: 26rem;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.titleSide {
  text-align: left;
  padding-left: 30px;
  height: 100%;
  margin: auto;
  align-items: center;
}
.photoSide img {
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b3b3b313;
}
::-webkit-scrollbar-thumb {
  background: #a480b7a6;
}

::-webkit-scrollbar-thumb:hover {
  background: #a480b7;
}
.textScroll {
  overflow-y: scroll;
  max-height: 350px;
  padding-right: 10px;
}

@media all and (max-width: 991px) {
  .headerContainer .row {
    padding-right: 0px;
  }
  .titleSide {
    padding-left: 20px;
    padding-right: 20px;
  }
  .titleSide p {
    color: #4d4d4c;
  }
  .textScroll {
    overflow-y: hidden;
    max-height: none;
    padding-right: 0px;
  }
}
@media all and (max-width: 575px) {
  .headerContainer .row {
    padding-right: 0px;
  }
}
</style>