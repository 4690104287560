<template>
  <div class="conatiner">
    <Navbar />
    <div class="fullContent">
      <div class="mt-0 p-2" style="background-color: #f5f2f4">
        <div
          class="
            row
            pr-0
            pl-0
            ml-0
            mr-0
            searching
            d-flex
            justify-content-between
          "
        >
          <div class="searchBox1 col-12 col-sm-6 pl-0">
            <div class="form-outline blackColor seacrh mt-3 mb-3">
              <form action="">
                <input
                  type="search"
                  id="form1"
                  class="form-control"
                  :placeholder="$t('front.search')"
                  aria-label="Search"
                  v-model="search"
                />
              </form>
            </div>
          </div>
          <div class="searchBox2 col-12 col-sm-6 pr-0">
            <select
              v-model="blogFilter"
              class="form-control blackColor seacrh mt-3 mb-3 pr-0"
            >
              <option :value="'null'">
                {{ $t("front.blogWithOUTtheme") }}
              </option>
              <option
                v-for="item in categories"
                :key="item._id"
                :value="item._id"
              >
                <span
                  class="titleBlog"
                  v-if="lang == 'ka'"
                  v-html="item.titleKA"
                  >{{ item.titleKA }}</span
                >
                <span v-else v-html="item.titleEN">{{ item.titleEN }}</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <BlogsHeader />
      <div class="mainBox">
        <div class="pt-2 mainC">
          <div class="mainContainer" v-if="!isLoading && !isEmpty">
            <div class="card" v-for="item in items" :key="item.id">
              <div class="textSide p-3 d-flex align-items-center">
                <div class="row d-flex align-items-center">
                  <div class="col-12 titleWidth" style="width: 200px">
                    <p
                      v-if="lang == 'ka'"
                      class="purple mtavruli blogTitle"
                      v-html="item.titleKA"
                    ></p>
                    <p
                      v-else
                      class="purple mtavruli blogTitle"
                      v-html="item.titleEN"
                    ></p>
                  </div>
                  <div class="col-12 blackColor">
                    <p
                      v-if="lang == 'ka'"
                      class="blackColor mtavruli titleWidth"
                      v-html="item.subTitleKA"
                    ></p>
                    <p
                      v-else
                      class="blackColor mtavruli titleWidth"
                      v-html="item.subTitleEN"
                    ></p>
                  </div>
                  <div class="col-12 blogText">
                    <p
                      v-if="lang == 'ka'"
                      class="blackColor pr-3"
                      v-html="removeHTML(subStringText(item.descriptionKA))"
                    ></p>
                    <p
                      v-else
                      class="blackColor pr-3"
                      v-html="removeHTML(subStringText(item.descriptionEN))"
                    ></p>
                    <div class="newsArch">
                      <router-link
                        class="btnArchive"
                        :to="'/blog' + '/' + item.slug"
                      >
                        {{ $t("front.more") }}
                      </router-link>
                    </div>
                  </div>
                  <div class="line mt-1"></div>
                  <div
                    class="col date mt-2 blackColor"
                    v-if="lang == 'ka'"
                    v-html="item.dateKA"
                  ></div>
                  <div
                    class="col date mt-2 blackColor"
                    v-else
                    v-html="item.dateEN"
                  ></div>
                </div>
              </div>
              <div
                v-if="item.image"
                :style="`backgroundImage: url(${server_url}/${item.image});`"
                class="imgSide"
              ></div>
              <div class="imgSide" v-else>
                <iframe
                  width="100%"
                  height="100%"
                  :src="item.video"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div v-else-if="isEmpty">category not found</div>
          <div v-else-if="isLoading" class="skeletonWIDTH">
            <div class="row g-2">
              <div
                class="col col-sm-6 mt-4 col-12"
                v-for="item in [1, 2, 3, 4]"
                :key="item"
              >
                <b-skeleton-img
                  no-aspect
                  height="18rem"
                  type="input"
                ></b-skeleton-img>
              </div>
            </div>
          </div>
          <div
            class="
              d-flex
              col-12
              align-itsmes-cenetr
              mt-5
              justify-content-center
            "
          >
            <b-pagination
              v-if="items.length > 0 && !isEmpty"
              v-model="currentPage"
              :total-rows="paginationLength"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <div
            class="mb-5"
            style="text-transform: uppercase"
            v-if="items.length == 0 && isLoading == false"
          >
            <h5
              style="text-transform: uppercase"
              class="mtavruli d-flex justify-content-center"
            >
              {{ $t("front.didnotFind") }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import axios from "axios";
import env from "../../../../env.json";
import BlogsHeader from "./BlogsHeader.vue";
export default {
  components: {
    Navbar,
    Footer,
    BlogsHeader,
  },
  data() {
    return {
      server_url: env.server_url,
      items: [],
      defaultItems: [],
      currentPage: 1,
      perPage: 4,
      paginationLength: "",
      isLoading: false,
      categories: [],
      categirysId: "",
      blogFilter: "null",
      lang: "ka",
      search: "",
      isEmpty: false,
      timer: null,
      timeout: 500,
    };
  },
  watch: {
    currentPage: function (newValue) {
      axios
        .get(
          `${env.host}/get/pagination/blog/${this.blogFilter}/${this.currentPage}/${this.perPage}`
        )
        .then((result) => {
          // console.log("araris22");
          // console.log(
          //   this.currentPage +
          //     "current   " +
          //     this.perPage +
          //     "per   " +
          //     this.blogFilter +
          //     "blog"
          // );

          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
        });
    },
    blogFilter: function (val) {
      const isNull = val == "null" ? true : false;
      let requestUrl = "";
      // carieli da null
      if (!this.search && isNull) {
        requestUrl = `${env.host}/get/pagination/blog/${val}/${this.currentPage}/${this.perPage}`;
        // console.log("araris22");
      }
      // carieli da kategoria
      else if (!this.search && !isNull) {
        this.currentPage = 1;
        this.perPage = 4;
        requestUrl = `${env.host}/get/filter-by-pagination/blog/${val}/${this.currentPage}/${this.perPage}`;
      } else if (this.search && !isNull) {
        requestUrl = `${env.host}/search/blog/filter-by-pagination/${val}/${
          this.search
        }/${1}/${4}`;
      } else {
        requestUrl = `${env.host}/search/blog/filter-by-pagination/${val}/${
          this.search
        }/${1}/${4}`;
      }
      axios.get(requestUrl).then((result) => {
        if (result.data.item) {
          this.items = result.data.item;
          this.paginationLength = result.data.total_page;
          if (!result.data.item) {
            this.isEmpty = true;
          }
        }
      });
    },
    search: function (newValue) {
      clearTimeout(this.timer);
      const isNull = this.blogFilter == "null" ? true : false;
      let requestUrl = "";
      // carieli da null
      if (!newValue && isNull) {
        requestUrl = `${env.host}/get/pagination/blog/${this.blogFilter}/${this.currentPage}/${this.perPage}`;
        // console.log("araris22");
      }
      // carieli da kategoria
      else if (!newValue && !isNull) {
        requestUrl = `${env.host}/get/filter-by-pagination/blog/${this.blogFilter}/${this.currentPage}/${this.perPage}`;
      } else if (newValue && !isNull) {
        requestUrl = `${env.host}/search/blog/filter-by-pagination/${
          this.blogFilter
        }/${newValue}/${1}/${4}`;
      } else {
        requestUrl = `${env.host}/search/blog/filter-by-pagination/${
          this.blogFilter
        }/${newValue}/${1}/${4}`;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.currentPage = 1;
        this.perPage = 4;
        axios.get(requestUrl).then((result) => {
          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        });
      }, this.timeout);
    },
  },
  methods: {
    myFunction() {
      console.log(this.search);
    },
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    subStringText: function (string) {
      return (
        string.substring(3, 220).split("&nbsp;").join("").split("·&").join("") +
        "..."
      );
    },
  },
  mounted() {
    this.isLoading = true;
    axios.get(`${env.host}/get/all/categories/user`).then((result) => {
      this.categories = result.data.item;
      this.categirysId = result.data.item[0]._id;

      axios
        .get(
          `${env.host}/get/pagination/blog/${this.blogFilter}/${this.currentPage}/${this.perPage}`
        )
        .then((result) => {
          this.items = [...result.data.item];
          this.defaultItems = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
      if (localStorage.getItem("lang")) {
        this.lang = localStorage.getItem("lang");
      }
    });
  },
};
</script>

<style scoped>
.blogTitle >>> * {
  font-family: mtavruli;
}
.titleBlog h5 {
  font-size: 16px !important;
}
.fullContent {
  min-height: calc(100vh - 190px);
}
/* search blog stylw */
.searching {
  width: 100%;
  max-width: 1400px;
  margin: auto !important;
}
.form-control {
  border-radius: 0px;
}
.form-control:focus {
  border-color: #7e5493;
  box-shadow: none;
}
/* search blog stylw */
.mainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.mainC {
  max-width: 1400px;
  margin: auto;
  width: 100%;
  margin: auto;
  justify-content: center;
}
.skeletonWIDTH {
  max-width: 1400px;
  margin: auto;
  justify-content: center;
  width: 100%;
}

.imgSide {
  background-color: #4d4d4cb4;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.imgSide1 {
  background-color: #4d4d4c;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card {
  display: grid;
  border: 2px solid rgba(0, 0, 255, 0) !important;
  grid-template-columns: 1fr 1fr;
  height: 350px;
  max-height: 350px;
  align-items: center;
  margin: auto;
}
.textSide {
  width: 100%;
  height: 100%;
  background-color: #f5f2f4;
}
.blogText p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.titleWidth {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;

  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 200px;
}
p {
  margin-bottom: 10px !important;
}
.newsArch {
  margin-right: 0px;
}
.btnArchive {
  border: none;
  color: #7e5493;
  font-family: mtavruli;
  text-transform: uppercase;
}
.line {
  height: 1px;
  background-color: #4d4d4c;
  width: 95%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
}
.conatiner {
  margin: auto;
}

.date p {
  color: #4d4d4c;
}
::v-deep .page-link {
  color: #bdbdbd;
}
::v-deep .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7e5493 !important;
  border-color: #7e5493 !important;
}
::v-deep .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #7e54935b !important;
}
@media all and (max-width: 1500px) {
  .mainBox {
    padding-left: 20px;
    padding-right: 20px;
  }
  .searching {
    margin: auto;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .searchBox1 {
    padding-right: 10px;
  }
}
@media all and (max-width: 1329px) {
  .newsArch {
    margin-right: 0px;
    float: none;
    margin-right: 0px;
    margin-top: 0px;
  }
}
@media all and (max-width: 960px) {
  .mainContainer {
    display: grid;
    grid-template-columns: 1fr;
  }

  .titleWidth {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
@media all and (max-width: 576px) {
  .card {
    display: grid;
    grid-template-columns: 1fr;
    height: 500px;
    max-height: 500px;
  }
  .imgSide {
    height: 200px;
    margin-top: -33px;
  }
  .titleWidth {
    width: auto;
    word-break: break-word;
  }
  .line {
    width: 98%;
  }
  .searchBox1 {
    padding-right: 0px;
  }
  .searchBox2 {
    padding-left: 0px;
    padding-right: 20px;
  }
}
@media all and (max-width: 355px) {
  .titleWidth {
    width: 150px;
  }
}
</style>